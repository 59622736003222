<template>
    <v-container style="max-width:100vw;">
        <!--totals v-bind:totalsFilters="{'selectedStatus':selectedStatus,'prop_brand_id':prop_brand_id,'prop_agency_id':prop_agency_id}"/-->
        <!-- Filtros -->
        <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="filters" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <filterExpenses @filtersExpense="filtersExpense"/>
        </v-navigation-drawer>
        <!-- Contenedor show-expand -->
        <v-data-table  
        :headers="showHeaders" 
        :items="expenses" 
        class="elevation-0 px-6 py-4"
        group-by="month" 
        height="600"
        fixed-header
        :footer-props="{'items-per-page-options':[15, 30, 50, expensesLength]}"
        v-if="showTable"
        :options.sync="options"
        v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
        :key="anIncreasingNumber" 
        :server-items-length="totalExpenses"
        :loading="loading"
        :item-class="itemRowBackground" >
            <!-- Header -->
            <template v-slot:group.header="{ items }">

                <td><strong>{{items[0].month}}</strong></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </template>
            <template v-slot:top>
                <v-toolbar flat class="mb-2">
                    <v-toolbar-title>Gastos</v-toolbar-title>

                    <v-btn icon v-if="filterStorageLength==0" @click="openFilter()">
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>

                    <v-btn icon v-else @click="openFilter()">
                        <v-badge overlap color="primary">
                            <template v-slot:badge>
                                {{filterStorageLength}}
                            </template>
                            <v-icon>mdi-filter</v-icon>
                        </v-badge>
                    </v-btn>


                    <v-spacer></v-spacer>
                    <v-select style="max-width:350px!important;" class="mr-4 mt-6" v-model="selectedHeaders" :items="headers" label="Columnas" dense multiple outlined return-object>
                        <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggle">
                            <v-list-item-action>
                                <v-icon :color="selectedHeaders.length > 0 ? 'primary' : ''">
                                {{ icon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                Seleccionar Todo
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" x-small v-if="index < 2">
                                <span style="font-size:11px;">{{ item.text }}</span>
                            </v-chip>
                            <span v-if="index === 2" style="font-size:10px!important;" class="grey--text caption">(+{{ selectedHeaders.length - 2 }} más)</span>
                        </template>
                    </v-select>
                    <v-btn icon v-show="permissions('downloadExpenses')">
                        <v-icon @click="exportExcel">mdi-download</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.percentage_over_sale`]="{ item }">
                {{(item.percentage_over_sale*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}
            </template><template v-slot:[`item.percentage_over_sale`]="{ item }">
                {{item.percentage_over_sale}} %
            </template>
            <template v-slot:[`item.amount`]="{ item }">
                {{(item.amount*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}
            </template>


            <!-- Acciones por fila -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list style="font-size:13px;">
                        <v-list-item @click="liquidar(item.id)" v-if="permissions('markExpensesAsPaid')">
                            <v-icon small class="mr-2">
                                mdi-cash-check
                            </v-icon>
                            Gasto Pagado
                        </v-list-item>
                        <v-list-item @click="editItem(item.editedItem)"  v-show="permissions('editExpenses')">
                            <v-icon small class="mr-2">
                                mdi-pencil
                            </v-icon>
                            Editar
                        </v-list-item>
                        <v-list-item @click="cancelItem(item.id)"  v-show="permissions('cancelExpenses')">
                            <v-icon small class="mr-2">
                                mdi-close
                            </v-icon>
                            Cancelar
                        </v-list-item>
                        <v-list-item @click="deleteItem(item.id)"  v-show="permissions('deleteExpenses')">
                            <v-icon small class="mr-2">
                                mdi-delete
                            </v-icon>
                            Eliminar
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <!-- Creación -->
            <template v-slot:[`item.created_at`]="{ item }">
                {{item.created_at.slice(0, 10)}}
            </template>
            <!-- Actualización -->
            <template v-slot:[`item.updated_at`]="{ item }">
                {{item.updated_at.slice(0, 10)}}
            </template>
            <template v-slot:[`item.pdf`]="{ item }">
                <v-btn v-if="item.pdf!=undefined" v-bind:href="'https://unopack.mx/files/' + item.pdf" target="_blank" icon>
                    <v-icon small>mdi-file-pdf</v-icon>
                </v-btn>
            </template>
            <!-- Tabla sin información -->
            <template v-slot:no-data>
                No existen registros de gastos aún
            </template>
        </v-data-table>
        <!-- Editar gasto -->
        <v-dialog v-model="editDialog" max-width="700px">
          <edit v-bind:expense="expense" @closeDialogEditExpense="closeDialogEditExpense"/>
        </v-dialog>
        <!-- Dialogo confirmación de eliminación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro(a) que deseas borrar este gasto?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="deleteExpense()">
                    Eliminar
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    Cancelar
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <!-- Dialogo confirmación de eliminación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="cancel_sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro(a) que deseas cancelar este gasto?
                    </div>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    No
                    </v-btn>
                    <v-btn class="mt-4" text color="error" @click="cancelNow()">
                    Si
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <!-- Gasto Pagado -->
        <v-dialog v-model="liquidarDialog" max-width="400px">
            <div class="px-12 py-6" style="background-color:white;">
                Gasto Pagado:
                <v-menu v-model="datePicker3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="pb-6 mt-4" :rules="[v => !!v || 'Campo requerido']" clearable required v-model="expense.pay_day" label="Fecha de Pago" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="expense.pay_day" @input="datePicker3 = false"></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="promiseDateDialog=false">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveLiquidacion()">
                    Guardar
                </v-btn>
            </div>
        </v-dialog>
    </v-container>
</template>

<script>
    function watchClass(targetNode, classToWatch) {
        let lastClassState = targetNode.classList.contains(classToWatch);
        const observer = new MutationObserver((mutationsList) => {
            for (let i = 0; i < mutationsList.length; i++) {
            const mutation = mutationsList[i];
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                const currentClassState = mutation.target.classList.contains(classToWatch);
                if (lastClassState !== currentClassState) {
                lastClassState = currentClassState;
                if (!currentClassState) {
                    mutation.target.classList.add('sortHandle');
                }
                }
            }
            }
        });
        observer.observe(targetNode, { attributes: true });
    }
    import Sortable from 'sortablejs';
    import axios from "axios";
    import XLSX from 'xlsx';
    import Filter from "../expenses/filter"
    import Create from "../expenses/create"
    import Edit from "../expenses/edit"
    import Totals from "../expenses/totals"
    export default {
        components: {
            'filterExpenses':Filter,
            'create':Create,
            'edit':Edit,
            'totals':Totals
        }, 
        data: () => ({
            liquidarDialog:false,
            anIncreasingNumber: 1,
            chip1:true,
            expense:'',
            sheet: false,
            canceled_item_id: '',
            cancel_sheet: false,
            filters: false,
            editDialog: false,
            createDialog: false,
            deleteId:'',
            expenses: '',
            headers:[],
            selectedHeaders: [],
            options: {},
            showTable:true,
            totalExpenses:0,
            loading:false,
            expensesLength:0,
            datePicker3:false
        }),
        computed: {
            filterStorageLength(){
                return localStorage.getItem("filtersExpensesLength")*1
            },
            showHeaders () {
                if(this.expenses!=''){
                    return this.headers.filter(s => this.selectedHeaders.includes(s));
                }else{
                    this.expenses = this.expensesLists
                    return this.headers.filter(s => this.selectedHeaders.includes(s));
                }
            },
            currentUser(){
                return this.$store.state.currentUser.user;
            },
            headersMap(){ 
                return [
                { text: 'Folio', value: 'id' },
                { text: 'Concepto', value: 'concept' },
                { text: 'Influencer', value: 'influencer', sortable: false},
                { text: 'Productor', value: 'user', sortable: false},
                { text: 'Responsable', value: 'responsable' },
                { text: 'Venta', value: 'sale' },
                { text: 'Total Gasto', value: 'amount' },
                { text: 'Monto Campaña', value: 'sale_amount' },
                { text: 'Monto Influencer', value: 'influencer_amount' },
                //{ text: '% de Venta', value: 'percentage_over_sale' },
                { text: 'Fecha Cierre', value: 'deadline_date' },
                { text: 'Fecha Promesa', value: 'payment_date' },
                { text: 'Fecha de Pago', value: 'pay_day' },
                { text: '', value: 'actions', sortable: false, align: 'end', },
            ]},
            likesAllFruit () {
                return this.selectedHeaders.length === this.headersMap.length
            },
            likesSomeFruit () {
                return this.selectedHeaders.length > 0 && !this.likesAllFruit
            },
            icon () {
                if (this.likesAllFruit) return 'mdi-close-box'
                if (this.likesSomeFruit) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
        },
        created () {
            this.headers = Object.values(this.headersMap);
            this.selectedHeaders = this.headers;
        },
        directives: {
            'sortable-table': {
                inserted: (el, binding) => {
                    el.querySelectorAll('th').forEach((draggableEl) => {
                        // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
                        watchClass(draggableEl, 'sortHandle');
                        draggableEl.classList.add('sortHandle');
                    });
                    Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {});
                },
            },
        },
        watch: {
            options: {
                handler () {
                    if(JSON.stringify(this.currentUser).length>10){
                        this.getDataFromApi()
                    }
                },
                deep: true,
            },
            currentUser: {
                handler () {
                    this.getDataFromApi()
                },
                deep: true,
            },
        },
        methods: {
            liquidar(id){
                this.expense = {
                    id:id,
                    pay_day:''
                }
                this.liquidarDialog = true
            },
            saveLiquidacion(){
                var editedItem = {
                    id:this.expense.id,
                    pay_day:this.expense.pay_day
                }
                axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sale/mark_as_paid/" + editedItem.id, editedItem).then(response=>{
                    this.liquidarDialog = false
                    this.getDataFromApi()
                })
            },
            itemRowBackground: function (item) {
                if(item.status=='cancelado'){
                    return 'style-1'//amarillo
                }if(item.pay_day!=null){
                    return 'style-3'//amarillo
                }
            },
            getDataFromApi () {
                this.loading = true
                this.apiCall().then(data => {
                    this.expenses = data.items
                    this.totalExpenses = data.total
                    this.loading = false
                })
            },
            apiCall () {
                return new Promise((resolve, reject) => {
                    const { sortBy, sortDesc, page, itemsPerPage } = this.options
                    var items = []
                    var total = 0
                    var link = ''
                    if(localStorage.getItem('filtersExpenses')!=null){
                        link = link + JSON.parse(localStorage.getItem('filtersExpenses'))+'&'
                    }
                    if(this.influencer!=undefined){
                        link = link + 'filter[influencer_id]=' + this.influencer + '&'
                    }
                    if(sortBy.length === 1){
                        if(sortDesc[0]){
                            link = link + "sort=-" + sortBy[0] + '&'
                        }else{
                            link = link + "sort=" + sortBy[0] + '&'
                        }
                    }
                    axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/expenses?" + link + "page=" + page + "&itemsPerPage=" + itemsPerPage).then(response => {
                        this.expensesLength = response.data.meta.total
                        items = this.mapExpenses(response.data.data)
                        total = response.data.meta.total
                        resolve({
                            items,
                            total,
                        })
                    })
                })
            },
            mapExpenses(expenses){
                expenses =  expenses.map(id=>{
                    return{
                        pay_day:id.pay_day,
                        status:id.status,
                        id:id.id,
                        influencer: this.influencerName(id.influencer),
                        sale: this.saleName(id.sale),
                        responsable: this.userName(id.sale.responsible_id),
                        payment_date: id.payment_date,
                        month: this.promesadepago(id.deadline_date),
                        deadline_date: this.deadline_date(id.sale),
                        concept: id.concept,
                        percentage_over_sale: this.expense_percentage(id.sale, id.amount).toFixed(2),
                        amount: id.amount,
                        sale_id: id.sale,
                        concept:id.concept,
                        user:this.userName(id.user),
                        editedItem:id,
                        sale_amount:(id.sale.subtotal*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',}),
                        influencer_amount:(id.sale.subtotal - id.amount).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})
                    }
                })
                return expenses
            },
            deadline_date(sale){
                if(sale!=undefined){
                    return sale.deadline_date
                }else{
                    return ''
                }
            },
            expense_percentage(sale, expense_amount){
                if(sale!=undefined){
                    return (100/sale.subtotal)*expense_amount
                }else{
                    return ''
                }
            },
            saleName(sale){
                if(sale!=undefined){
                    return sale.id + ' | ' + this.name(sale.agency, sale.brand)
                }else{
                    return ''
                }
            },
            influencerName(item){
                if(item!=undefined){
                    return item.social_networks.instagram
                }else{
                    return ''
                }
            },
            userName(item){
                if(item!=undefined){
                    return item.name
                }else{
                    return ''
                }
            },
            name(agency, brand){
                var agency_name = ''
                var brand_name = ''
                var name = ''
                if(agency!=undefined){
                    agency_name = agency.name
                }
                if(brand!=undefined){
                    brand_name = brand.name
                }
                if(agency!=undefined && brand!=undefined){
                    return agency_name + ' | ' + brand_name
                }else if(agency!=undefined){
                    return agency_name
                }
                else if(brand!=undefined){
                    return brand_name
                }else{
                    return ''
                }
            },
            promesadepago(date){
                return this.month(date.slice(5,7)) + ' ' + date.slice(0,4)
            },
            month(date){
                var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
                return meses[(date*1)-1]
            },
            sortTheHeadersAndUpdateTheKey(evt) {
                const headersTmp = this.showHeaders;
                const oldIndex = evt.oldIndex;
                const newIndex = evt.newIndex;
                if (newIndex >= headersTmp.length) {
                    let k = newIndex - headersTmp.length + 1;
                    while (k--) {
                        headersTmp.push(undefined);
                    }
                }
                headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
                this.table = headersTmp;
                this.anIncreasingNumber += 1;
            },
            toggle () {
                this.$nextTick(() => {
                    if (this.likesAllFruit) {
                        this.selectedHeaders = []
                    } else {
                        this.selectedHeaders = this.headersMap.slice()
                    }
                })
            },
            booking(id){
                return this.$store.state.user.users.filter(user=>user.id == id).map(influencer=>influencer.booking)[0]
            },
            influencerId(items){
                for(var i=0; i<items.length; i++){
                    if(items[i].influencer_id == this.influencer){
                        return true
                    }
                }
            },
            openFilter(){
                if(this.filters == false){
                    this.$emit("closeDrawer", false);
                    this.filters = true
                }else{
                    this.$emit("closeDrawer", true);
                    this.filters = false
                }
            },
            permissions(permission){
                if(this.currentUser.id==1){
                    return true
                }else if(this.currentUser.permissions!=undefined){
                    if(this.currentUser.permissions.includes(permission)){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return false
                }
            },
            filtersExpense: function(params) {
                this.getDataFromApi()
            },
            closeDialogEditExpense: function(params) {
                this.editDialog = false;
                this.getDataFromApi()
                this.$store.dispatch('expense/getTotals')
            },
            closeCreateDialogExpense: function(params) {
                this.createDialog = false;
                this.getDataFromApi()
                this.$store.dispatch('expense/getTotals')
            },
            exportExcel: function () {
                let data = XLSX.utils.json_to_sheet(this.expenses)
                const workbook = XLSX.utils.book_new()
                const filename = 'Lista de Gastos'
                XLSX.utils.book_append_sheet(workbook, data, filename)
                XLSX.writeFile(workbook, `${filename}.xlsx`)
            },
            deleteExpense(){
                axios.delete(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/expenses/"+this.deleteId).then(response => {
                    this.deleteId = ''
                    this.sheet = false
                    this.getDataFromApi()
                });
            },
            cancel(){
                this.canceled_item_id = ''
                this.deleteId = ''
                this.cancel_sheet = false
                this.sheet = false
                this.sheet2 = false
            },
            deleteItem (id) {
                this.deleteId = id
                this.sheet = true
            },
            editItem(item){
                this.expense = [item].map(id=>{
                    return{
                        id:id.id,
                        sale_id:this.iD(id.sale),
                        percentage_over_sale:id.percentage_over_sale,
                        concept:id.concept,
                        amount:id.amount,
                        influencer_id:this.iD(id.influencer),
                        user_id:this.iD(id.user),
                        payment_date:id.payment_date,
                        deadline_date:id.deadline_date,
                        sale:id.sale,
                        influencer:id.influencer
                    }
                })[0]
                this.editDialog = true
            },
            cancelItem(id){
                this.canceled_item_id = id
                this.cancel_sheet = true
            },
            cancelNow(){
                axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/expenses/"+this.canceled_item_id, {status: 'cancelado'}).then(response => {
                    this.cancel_sheet = false
                    this.getDataFromApi()
                });
            },
            iD(item){
                if(item!=undefined){
                    return item.id
                }else{
                    return ''
                }
            }
        }
    }
</script>
<style>
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none!important;
        background: #f7f7f7;
    }
    .style-1 {
        background-color: #fff2ca;
    }
    .style-2 {
        background-color: #d9ebff;
    }
    .style-3 {
        background-color: #0080002b;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
        padding: 0 8px!important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
        font-size:13px!important;
    }
    .v-row-group__header .ma-0.v-btn.v-btn--icon.v-btn--round.theme--light.v-size--small:nth-child(2){
        display:none!important;
    }
    .v-data-table-header th {
        white-space: nowrap;
    }
    .theme--light.v-data-table .v-row-group__header, .theme--light.v-data-table .v-row-group__summary {
        background: #f4f5fa;
    }
    .v-text-field--outlined fieldset {
        border-color: #e0e0e0;
    }
    .v-list-item__action {
        margin: 0px 20px 0px 0px!important;
    }
    .v-menu__content.theme--light.menuable__content__active{
        max-height: 85vh!important;
    }

    .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
        padding: 0 0px!important;
    }
    .btnText2{
        padding: 0px!important;
        height: auto!important;
        background: rgb(219, 219, 219)!important;
        box-shadow: none!important;
        /*text-transform: none;*/
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        letter-spacing: 0;
        color: #1f1f1f!important;
        text-decoration: none!important;
    }
</style>